import React from 'react';
import './Posts.css';
import ControlledTabs from '../../../Assets/Tabs/Tabs';

const Posts = () => {
  return (
    <div className="section">
      <h1>Posts </h1>
      <ControlledTabs />
      
    </div>
  );
};

export default Posts;
