import React from 'react'
import './PostImageArea.css'
import { Stack } from 'react-bootstrap';
import AvatarAsst from '../../../Assets/Avatar';
import { usePostCategory } from '../../../Contexts/PostCategoryContext';
import { useNavigate } from 'react-router-dom';

function PostImageArea({ image, post, mainContentMargin }) {
    console.log(post);

    const { setPostCategory } = usePostCategory();
    const navigate = useNavigate();

    const handleCategoryClick = async (category) => {
        console.log(category);
        setPostCategory(category);

        const path = category.toLowerCase().replace(/\s/g, "-");

        navigate(`/category/${path}`);
    };

    // Convert createdAt to a Date object
    const date = new Date(post.createdAt);

    // Format the date and time
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    
    const apiUrl = `${process.env.REACT_APP_RENDER_EXTERNAL_URL}`;


    console.log(formattedDate)
    return (
        <div className='post-image-area'>
            <div className="post-image-section" style={{marginTop: mainContentMargin}}>
                <section className="hero">
                    <div className="post-image" style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                        url(${apiUrl + post.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '700px',
                    }}>
                    </div>
                    <div className="position-absolute top-50 start-50 translate-middle" style={{ width: '100%', left: 0 }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-texts text-center">
                                        {Array.isArray(post.category) && post.category.map(category => (
                                            <span key={category} onClick={() => handleCategoryClick(category)} className="category">{category}</span>
                                        ))}
                                        <h1 className="text-white mt-2">{post.title}</h1>
                                        <p className='author-info mt-2'>
                                            <Stack direction="horizontal" className='d-flex justify-content-center' spacing={3}>
                                                <AvatarAsst size={30} name={post.author} />
                                                <div className='me-2'>
                                                    <p style={{marginTop: '15px'}} className='ms-1 text-white'>{post.author}</p>
                                                </div>
                                            </Stack>
                                            <p style={{ margin: 0 }} className="text-white">{formattedDate} <span>&#9679;</span> {post.readTime} mins read</p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PostImageArea