import React from 'react';
import './Media.css';

const Media = () => {
  return (
    <div className="section">
      <h1>Media</h1>
      <p>You don't have any media. Would you like to upload something?</p>
      <button>Upload media</button>
      <button>Browse free images</button>
    </div>
  );
};

export default Media;
