import React from 'react'
import './LoadingPage.css'

function LoadingPage() {
  return (
    <div class="loading" >
      <div class="loading-text">
        <p class="loading-text-words">L</p>
        <p class="loading-text-words">O</p>
        <p class="loading-text-words">A</p>
        <p class="loading-text-words">D</p>
        <p class="loading-text-words">I</p>
        <p class="loading-text-words">N</p>
        <p class="loading-text-words">G</p>
      </div>
    </div >
  )
}

export default LoadingPage