import React, { useContext, useEffect, useState } from 'react';
import MainContent from '../../Components/UserComponents/MainContent/MainContent';
import BlogSection from '../../Components/UserComponents/BlogSection/BlogSection';
import SocialSection from '../../Components/UserComponents/SocialAdvertise/SocialSection';
import { PostsContext } from '../../Contexts/PostContext';
import imageCompression from 'browser-image-compression';
import { usePostCategory } from '../../Contexts/PostCategoryContext';
import LoadingPage from './LoadingPage/LoadingPage';
import axiosInstance from '../../axiosConfig'; // Updated import path

const LandingPage = ({ mainContentMargin }) => {
    const { posts, setPosts } = useContext(PostsContext);
    const { postCategory } = usePostCategory();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchPosts = async () => {
            setIsLoading(true);
            let fetchedPosts = null; // Change const to let
            let response; // Declare response variable
            try {
                if (postCategory) {
                    console.log(postCategory);
                    response = await axiosInstance.get(`/api/blogpost/categories/category`, {
                        params: { category: postCategory }
                    });
                } else {
                    response = await axiosInstance.get(`/api/blogpost/`);
                }
                fetchedPosts = response.data;

                // Add showContent property to each post
                const postsWithShowContent = fetchedPosts.map(post => ({
                    ...post,
                    showContent: stripHtmlTags(post.content)
                }));

                setPosts(postsWithShowContent);

                console.log(postsWithShowContent, response);

                if (response.status === 200) {
                    console.log('Posts fetched successfully:', postsWithShowContent);
                } else {
                    console.error('Error fetching posts:');
                }
            } catch (error) {
                console.error('An error occurred while fetching the posts:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPosts();
    }, [postCategory, setPosts]);

    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    return (
        <div className='main-div'>
            {isLoading ? (
                <LoadingPage />
            ) : (
                <div>
                    <MainContent mainContentMargin={mainContentMargin} />
                    <BlogSection />
                    <SocialSection />
                </div>
            )
            }
        </div>

    )
}

export default LandingPage;