import axios from 'axios';

// Log the environment variable
console.log('Environment Variable:', process.env.REACT_APP_RENDER_EXTERNAL_URL);

// Create an Axios instance with the base URL
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_RENDER_EXTERNAL_URL,
});

// Export the Axios instance
export default axiosInstance;