import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2>Dashboard</h2>
      <Button variant="contained">Hello world</Button>
      <List>
        <ListItem button component={Link} to="/sk-admin">
          <ListItemText primary="My Home" />
        </ListItem>
        <ListItem button component={Link} to="/sk-admin/posts">
          <ListItemText primary="Posts" />
        </ListItem>
        <ListItem button component={Link} to="/sk-admin/media">
          <ListItemText primary="Media" />
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;
