import React from 'react'

function Maintanence() {
    return (
        <div className='position-relative' style={{ height: '90vh' }}>
            <p className='text-center position-absolute top-50 start-50 translate-middle' style={{
                fontSize: '20px',
                color: '#3b6ab2',
                fontWeight: '600'
            }}>
                We are currently under maintanence. Please check back later.
                <br />
                <i class="fa-solid fa-person-digging fa-lg p-5" style={{
                    color: '#3b6ab2',
                    fontSize: '100px',
                }}></i>
            </p>

        </div>
    )
}

export default Maintanence