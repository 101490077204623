import React, { createContext, useState, useContext } from 'react';

const PostCategoryContext = createContext();

export const PostCategoryProvider = ({ children }) => {
    const [postCategory, setPostCategory] = useState('');

    return (
        <PostCategoryContext.Provider value={{ postCategory, setPostCategory }}>
            {children}
        </PostCategoryContext.Provider>
    );
};

// Custom hook for easy context usage
export const usePostCategory = () => useContext(PostCategoryContext);