import React, { useContext, useEffect, useState, useMemo } from 'react';
import './MainContent.css';
import { Carousel } from 'react-bootstrap';
import CarouselItems from '../../../Assets/CarouselItems/CarouselItems';
import { usePostCategory } from '../../../Contexts/PostCategoryContext';
import { PostsContext } from '../../../Contexts/PostContext';
import { Helmet } from 'react-helmet';

const MainContent = ({ mainContentMargin }) => {
    const [mainContent, setMainContent] = useState([]);

    const { postCategory } = usePostCategory()
    const { posts, setPosts } = useContext(PostsContext);
    const currentUrl = window.location.href; // Get the current page URL

    const MAX_ITEMS = 4;

    // useEffect(() => {
    //     const fetchMainContent = async () => {

    //         let posts = null; // Change const to let
    //         let response; // Declare response variable

    //         try {
    //             if (postCategory) {
    //                 console.log(postCategory);
    //                 response = await fetch(`/api/blogpost/categories/category?category=${postCategory}`);
    //                 posts = await response.json();
    //             } else {
    //                 response = await fetch('/api/blogpost/');
    //                 posts = await response.json();
    //             }

    //             console.log(posts, response);

    //             if (response.ok && posts) { // Check if response is ok and posts is not null
    //                 const postsWithImages = await Promise.all(
    //                     posts.map(async (post) => {
    //                         const imageUrl = await fetchImage(post.image);
    //                         return { ...post, imageUrl, showContent: stripHtmlTags(post.content) };
    //                     })
    //                 );
    //                 setMainContent(postsWithImages);
    //             }
    //         } catch (error) {
    //             console.error("Failed to fetch posts:", error);
    //         } finally {
    //         }
    //     };

    //     const fetchImage = async (imageId) => {
    //         try {
    //             const response = await fetch(`/api/image/${imageId}`);
    //             if (response.ok) {
    //                 const blob = await response.blob();
    //                 return URL.createObjectURL(blob);
    //             } else {
    //                 console.error('There was an error fetching the image!');
    //                 return null;
    //             }
    //         } catch (error) {
    //             console.error('There was an error fetching the image!', error);
    //             return null;
    //         }
    //     };

    //     fetchMainContent();
    // }, [postCategory, posts]); // Dependency array, re-run effect if postCategory changes


    // const stripHtmlTags = (str) => {
    //     if (!str) return '';
    //     return str.replace(/<\/?[^>]+(>|$)/g, '');
    // };

    const memoizedPosts = useMemo(() => posts, [posts]);
    console.log(memoizedPosts);

    return (

        <main className="main-content mb-5" style={{ marginTop: mainContentMargin }}>
            <Helmet>
                {postCategory ?
                    <div>
                        <title>{`${postCategory} | Sakshi Online`}</title>
                        <meta property="og:title" content={`${postCategory} | Sakshi Online`} />
                        <meta property="og:description" content={`${postCategory} | Sakshi Online`} />
                        <meta property="og:image" content={memoizedPosts[0].imageUrl} />
                        <meta property="og:url" content={currentUrl} />
                        <meta property="og:type" content={`${postCategory}`} />
                    </div>
                    : <title>Sakshi Online</title>}

            </Helmet>
            <section className="hero">
                <Carousel controls={false}>
                    {memoizedPosts.slice(0, MAX_ITEMS).map((post, index) => (
                        <Carousel.Item key={index}>
                            <CarouselItems post={post} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </section>
        </main>
    );
};

export default MainContent;
