import * as React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2); 
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name, size) {
    let initials = name.split(' ')[0][0]; // Always get the first letter of the first word
    if (name.split(' ')[1]) { // Check if there is a second word
        initials += name.split(' ')[1][0]; // If so, add the first letter of the second word
    }
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: size,
            height: size,
            fontSize: size / 2,
        },
        children: initials,
    };
}

export default function AvatarAsst({ name, size }) {
    return (
        <Avatar {...stringAvatar(name, size)} />
    );
}

