import React, { useContext, useEffect, useState } from 'react'
import PostImageArea from '../../Components/UserComponents/PostViewImage/PostImageArea'
import { PostContext } from '../../Contexts/PostContext';
import PostArticleArea from '../../Components/UserComponents/PostViewContent/PostArticleArea';
import { useLocation, useParams } from 'react-router-dom';
import LoadingPage from './LoadingPage/LoadingPage';
import axiosInstance from '../../axiosConfig';
import { Helmet } from 'react-helmet';


function PostView({ mainContentMargin }) {

  // const { post } = useContext(PostContext);
  // console.log(post.imageUrl);
  // const image = post.imageUrl;

  const currentUrl = window.location.href; // Get the current page URL
  const location = useLocation();
  // const { postId } = location.state || {};
  const { postId } = useParams();

  console.log(postId);

  const [postID, setPostID] = useState(postId || '');
  const [postTitle, setPostTitle] = useState('');
  const [postContent, setPostContent] = useState('');
  const [postAuthor, setPostAuthor] = useState('');
  const [postCategory, setPostCategory] = useState('');
  const [postReadTime, setPostReadTime] = useState('');
  const [postDate, setPostDate] = useState('');
  const [imageUrl, setImageUrl] = useState("")

  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = `${process.env.REACT_APP_RENDER_EXTERNAL_URL}`;


  useEffect(() => {
    const fetchBlogPost = async (postId) => {
      setIsLoading(true)
      const response = await axiosInstance.get(`/api/blogpost/${postId}`);
      const json = await response.data;

      if (response.status === 200) {

        setPostTitle(json.title);
        setPostContent(json.content);
        setPostAuthor(json.author);
        setPostCategory(json.category);
        setPostReadTime(json.readTime);
        setImageUrl(json.image);
        setPostDate(json.createdAt)
        console.log(json);

        setIsLoading(false);

        // if (json.image) {
        //   fetchImage(json.image);
        // }

      } else {
        console.error("There was an error fetching the blog post!");
      }
    };

    // const fetchImage = async (imageId) => {
    //   const response = await fetch(`/api/image/${imageId}`);
    //   if (response.ok) {
    //     const blob = await response.blob();
    //     const url = URL.createObjectURL(blob);
    //     setImageSrc(url);
    //   } else {
    //     console.error('There was an error fetching the image!');
    //   }
    // };

    if (postId) {
      fetchBlogPost(postId);
    }

  }, [postId]);

  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const post = {
    title: postTitle,
    content: postContent,
    showContent: stripHtmlTags(postContent),
    author: postAuthor,
    category: postCategory,
    readTime: postReadTime,
    image: imageUrl,
    createdAt: postDate,
  }

  

  console.log(post);

  return (
    <div>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <main className='main'>
          <Helmet>
            <title>{`${post.title.substring(0, 50)} | Sakshi Online`}</title>
            <meta property="og:title" content={`${post.title.substring(0, 50)} | Sakshi Online`} />
            <meta property="og:description" content={`${post.title.substring(0, 50)} | Sakshi Online`} />
            <meta property="og:image" content={post.imageUrl} />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="article" />
          </Helmet>
          <PostImageArea image={apiUrl + post.imageUrl} post={post} mainContentMargin={mainContentMargin} />
          <PostArticleArea post={post} />
        </main>
      )}
    </div>
  )
}

export default PostView