import React, { createContext, useState } from "react";


export const ChangedContext = createContext(null)


export default function ChangedContextProvider ({children}) {

    const [hasChanged, setHasChanged] = useState(false)
    
    return (
        <ChangedContext.Provider value={{hasChanged, setHasChanged}}>
            {children}
        </ChangedContext.Provider>
    )
};