import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ChangedContextProvider from './Contexts/ChangedContext';
import PostContextProvider from './Contexts/PostContext';
import { PostCategoryProvider } from './Contexts/PostCategoryContext';
import App from './App'; // Ensure you have this import
import axios from 'axios';
// import { createProxyMiddleware } from 'http-proxy-middleware';

// const proxy = createProxyMiddleware({
//   target: process.env.RENDER_EXTERNAL_URL,
//   changeOrigin: true,
//   pathRewrite: { '^/api': '' },
// });

// Log the environment variable
console.log('Environment Variable:', process.env.REACT_APP_RENDER_EXTERNAL_URL);

// Construct the full URL
const apiUrl = `${process.env.REACT_APP_RENDER_EXTERNAL_URL}`;
console.log('Requesting URL:', apiUrl);

// Example of using axios to make a request
// axios.get(apiUrl)
//   .then(response => {
//     console.log(response.data);
//   })
//   .catch(error => {
//     console.error('There was an error making the request!', error);
//   });

// Render your App component to the root
ReactDOM.render(
  <React.StrictMode>
    <PostContextProvider>
      <ChangedContextProvider>
        <PostCategoryProvider>
          <Router>
            <App />
          </Router>
        </PostCategoryProvider>
      </ChangedContextProvider>
    </PostContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
