import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Form, Nav, Row, Button } from 'react-bootstrap';
import { ChangedContext } from '../../Contexts/ChangedContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BlogPostPage.css';
import imageCompression from 'browser-image-compression';
import axiosInstance from '../../axiosConfig';

const BlogPostPage = (props) => {
    const { postId } = useParams();
    const [postID, setPostID] = useState(postId || '');
    const [postTitle, setPostTitle] = useState('');
    const [postContent, setPostContent] = useState('');
    const [postAuthor, setPostAuthor] = useState('');
    const [postCategory, setPostCategory] = useState('');
    const [postReadTime, setPostReadTime] = useState('');
    const [postImage, setPostImage] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [error, setError] = useState(null);
    const [imageSrc, setImageSrc] = useState("")
    const [imageUrl, setImageUrl] = useState('');

    const formRef = useRef(null);
    const titleRef = useRef(null);

    useEffect(() => {
        const fetchBlogPost = async (postId) => {
            const response = await axiosInstance.get(`/api/blogpost/${postId}`);
            const json = await response.data;

            if (response.status === 200) {

                setPostTitle(json.title);
                setPostContent(json.content);
                setPostAuthor(json.author);
                setPostCategory(json.category);
                setPostReadTime(json.readTime);


            } else {
                console.error("There was an error fetching the blog post!");
            }
        };

        if (postId) {
            fetchBlogPost(postId);
        }
    }, [postId]);

    const apiUrl = `${process.env.REACT_APP_RENDER_EXTERNAL_URL}`;


    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append('image', file);


        console.log(formData);

        try {
            const response = await axiosInstance.post('/api/image/upload', formData);

            if (response.status === 200) {
                const data = await response.data; // Assuming the response contains JSON data
                // return data; // Assuming the response contains the file ID
                console.log(data);
                return data;
            } else {
                console.error('Image upload failed');
                return null;
            }
        } catch (error) {
            console.error('Error during image upload', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let blogPostData;

        if (postImage) {
            const data = await handleImageUpload(postImage);
            const imageUrl = data.imageUrl;

            await setImageUrl(data.imageUrl);
            console.log(imageUrl);
            

            

            blogPostData = {
                title: postTitle,
                content: postContent,
                author: postAuthor,
                readTime: postReadTime,
                category: postCategory,
                image: imageUrl, // Save the image ID
                // compressedImage: compressedImageId // Save the compressed image ID
            };
        } else {
            blogPostData = {
                title: postTitle,
                content: postContent,
                author: postAuthor,
                readTime: postReadTime,
                category: postCategory
            };
        }


        try {
            let response;
            if (postID) {
                response = await axiosInstance.patch(`/api/blogpost/${postID}`, blogPostData);
            } else {
                response = await axiosInstance.post('/api/blogpost', blogPostData);
            }

            const json = await response.data;

            if (!response.status === 200) {
                setError(json.error);
            } else {
                setError(null);
                if (!postID) setPostID(json.id);
                console.log('Post saved successfully', json);
                setHasChanges(false);
                alert('Post saved successfully')
                setTimeout(() => {
                    navigate('/sk-admin/posts');
                }, 2000);
            }
        } catch (error) {
            console.error('There was an error saving the blog post!', error);
            setError('There was an error saving the blog post!');
        }
    };

    const handleChange = (e, setState) => {
        setState(e.target.value);

        setHasChanges(true);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPostImage(file);
            setHasChanges(true);
            console.log(postImage);
        }
    };

    useEffect(() => {
        if (postImage) {
            console.log(postImage);
        }
    }, [postImage]);

    const handleContentEditableInput = (e, setState) => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const startOffset = range.startOffset;

        setState(e.target.innerText)
        setHasChanges(true);

        window.requestAnimationFrame(() => {
            const newRange = document.createRange();
            const newNode = range.startContainer;
            const newOffset = Math.min(startOffset, newNode.length);

            newRange.setStart(newNode, newOffset);
            newRange.setEnd(newNode, newOffset);
            selection.removeAllRanges();
            selection.addRange(newRange);
        });
    };

    const navigate = useNavigate()

    const handleQuillChange = (content, delta, source, editor) => {
        setHasChanges(true);
        setPostContent(content);
        console.log(content);
    };

    function stringToUrl(urlString) {
        try {
            const url = new URL(urlString);
            return url;
        } catch (error) {
            console.error('Invalid URL:', error);
            return null;
        }
    }
    return (
        <div className='p-3'>
            <Container>
                <Nav>
                    <Nav.Item>
                        <Nav.Link onClick={() => navigate('/sk-admin/posts')}>Back to Admin</Nav.Link>
                    </Nav.Item>
                </Nav>

                <hr />

                <Row>
                    <Col md={10}>
                        <Container>
                            <Form ref={formRef} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <div
                                        className='form-control mb-3'
                                        contentEditable
                                        onInput={(e) => handleContentEditableInput(e, setPostTitle)}
                                        suppressContentEditableWarning={true}
                                        ref={titleRef}
                                        style={{
                                            fontSize: '35px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        {postTitle}
                                    </div>
                                    {/* <div
                                        className='form-control mb-3'
                                        contentEditable
                                        onInput={(e) => handleContentEditableInput(e, setPostContent)}
                                        suppressContentEditableWarning={true}
                                        ref={contentRef}
                                        style={{
                                            fontSize: '18px'
                                        }}
                                    >
                                        {postContent}
                                    </div> */}
                                    <ReactQuill
                                        className='form-control mb-3'
                                        value={postContent}
                                        onChange={handleQuillChange}
                                        placeholder="Enter the content here"
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link', 'image', 'video'],
                                                ['clean']
                                            ],
                                        }}
                                        formats={[
                                            'header', 'font', 'size',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent',
                                            'link', 'image', 'video'
                                        ]}
                                        style={{
                                            fontSize: '20px'
                                        }}
                                    />
                                    {/* <Form.Control
                                        onKeyUp={(e) => textAreaAdjust(e.target)}
                                        onChange={(e) => handleChange(e, setPostContent)}
                                        value={postContent}
                                        style={{ resize: 'none' }}
                                        as="textarea"
                                        className='mb-3'
                                        placeholder='Content'
                                    /> */}
                                    <Form.Group className="mb-3">
                                        <Form.Label >Author</Form.Label>
                                        <Form.Control
                                            onChange={(e) => handleChange(e, setPostAuthor)}
                                            value={postAuthor}
                                            className='mb-3'
                                            type='text'
                                            id='author'
                                            placeholder='Author'
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label >Category</Form.Label>
                                        {/* <div key={`default-checkbox`} className="mb-3">
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`cover`}
                                                label={`Cover`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['cover'] || false}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`article`}
                                                label={`Article`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['article'] || false}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`current-affairs`}
                                                label={`Current Affairs`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['current-affairs'] || false}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`fiction`}
                                                label={`Fiction`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['fiction'] || false}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`story`}
                                                label={`Story`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['story'] || false}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`poem`}
                                                label={`Poem`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['poem'] || false}
                                            />
                                            <Form.Check // prettier-ignore
                                                type={'checkbox'}
                                                id={`studies`}
                                                label={`Studies`}
                                                onChange={handleCheckboxChange}
                                                checked={selectedCheckboxes['studies'] || false}
                                            />
                                        </div> */}
                                        <Form.Control
                                            onChange={(e) => {
                                                handleChange(e, setPostCategory)

                                            }}
                                            value={postCategory}
                                            className='mb-3'
                                            type='text'
                                            id='category'
                                            placeholder='Category'
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="readTime">Read Time</Form.Label>
                                        <Form.Control
                                            onChange={(e) => handleChange(e, setPostReadTime)}
                                            value={postReadTime}
                                            className='mb-3'
                                            type='text'
                                            id='readTime'
                                            placeholder='Read Time'
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="image">Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            label="Upload Image"
                                            id="image"
                                            onChange={handleImageChange}
                                            className='mb-3'
                                        />
                                    </Form.Group>
                                    {postImage && <img src={URL.createObjectURL(postImage)} alt="Selected" style={{ width: '500px' }} />}
                                    {!postImage && <img src={imageSrc} alt="Selected" style={{ width: '500px' }} />}
                                    {imageUrl && (
                                        <div>
                                            <img src={apiUrl + imageUrl} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                    )}
                                    {error ? <div className="error mt-3" style={{ color: 'red' }}>{error}</div> : <div className=' mt-3' style={{ color: 'green' }}>Ok</div>}
                                </Form.Group>
                                <Button variant="primary" type="submit" disabled={!hasChanges}>Publish</Button>
                            </Form>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BlogPostPage;
