import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';


// pages and components
import LandingPage from './Pages/Users/LandingPage';
import BlogPostPage from './Pages/Admin/BlogPostPage';
import AdminLandingPage from './Pages/Admin/AdminLandingPage';
import CategoryPage from './Pages/Users/CategoryPage';
import PostView from './Pages/Users/PostView';
import Header from './Components/UserComponents/Header/Header';
import Footer from './Components/UserComponents/Footer/Footer';
import { PostsContext } from './Contexts/PostContext';
import Maintanence from './Pages/Users/Maintanence';
import Magazines from './Pages/Users/Magazines/Magazines';
import Maintenance from './Pages/Users/Maintanence';

function App() {
  const [mainContentMargin, setMainContentMargin] = useState('-125px');
 
  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={
          <div>
            <Header setMainContentMargin={setMainContentMargin} />
            <Routes>
              <Route path="/" element={<LandingPage mainContentMargin={mainContentMargin} />} />
              <Route path='category/:category' element={<CategoryPage mainContentMargin={mainContentMargin} />} />
              <Route path='post/:category/:postId' element={<PostView mainContentMargin={mainContentMargin} />} />
              <Route path='/magazines' element={<Maintenance />} /> 
              
            </Routes>
            <Footer />
          </div>
        } />
        <Route path="sk-admin/*" element={<AdminLandingPage />} />
        <Route path="sk-admin/blog-post/:postId" element={<BlogPostPage postStatus="editPost" />} />
        <Route path="sk-admin/blog-post" element={<BlogPostPage postStatus="addNew" />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  );
}

export default App;