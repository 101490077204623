import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Tabs.css';
import { useNavigate } from 'react-router-dom';
import { PostsContext } from '../../Contexts/PostContext';
import axiosInstance from '../../axiosConfig';

function ControlledTabs() {
    const [key, setKey] = useState('home');
    const [blogPosts, setBlogPosts] = useState([]);

    const { setPosts } = useContext(PostsContext);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await axiosInstance.get(`/api/blogpost/`);
                const json = await response.data;
                console.log(json);

                if (response.status === 200) {
                    setBlogPosts(json);
                } else {
                    console.error("There was an error fetching the blog posts!");
                }
            } catch (error) {
                console.error("There was an error fetching the blog posts!", error);
            }
        };

        fetchBlogPosts();
    }, []);

    const apiUrl = process.env.REACT_APP_RENDER_EXTERNAL_URL;
    const handleDeletePost = async (postId) => {
        try {
            const response = await axiosInstance.delete(`/api/blogpost/${postId}`);

            if (response.status === 200) {
                setBlogPosts((prevPosts) => prevPosts.filter(post => post._id !== postId));
                alert('Post deleted successfully');
                console.log('Post deleted successfully');
            } else {
                console.error('There was an error deleting the post!');
            }
        } catch (error) {
            console.error('There was an error deleting the post!', error);
        }
    };

    const navigate = useNavigate();
    console.log(blogPosts);

    const filteredBlogPosts = blogPosts.filter(post => post.status !== 'trash');
    console.log(filteredBlogPosts);

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="home" title="Published">
                <div className='section-header d-flex border border-1 p-2'>
                    <div className="section-header__label">
                        <span>Posts</span>
                    </div>
                    <div className="section-header__actions ms-auto">
                        <Button onClick={() => navigate('/sk-admin/blog-post')}>Add New Post</Button>
                    </div>
                </div>
                <ul>
                    {filteredBlogPosts.map((post) => (
                        <li className='border border-top-0 border-1 p-2 list-item' key={post._id}>
                            <Row>
                                <Col md={2}>
                                    {apiUrl && <img style={{width: '100px'}} src={apiUrl + post.image} alt={post.title} />}
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <h5 onClick={() => {
                                            navigate(`/sk-admin/blog-post/${post._id}`);
                                            setPosts(post);
                                        }}>{post.title}</h5>
                                        <p style={{margin: '0'}}>{post.author}</p>
                                        <span className='post-date'>{post.createdAt} <span>&#9679;</span> {post.readTime} mins read</span>
                                    </div>
                                </Col>
                                <Col md={2} className='text-center'>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle style={{ background: "#ffffff00" }}>
                                                <i className={`fa fa-ellipsis`}></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    navigate(`/sk-admin/blog-post/${post._id}`);
                                                    setPosts(post);
                                                }}><i className="fa-solid fa-pencil"></i> Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2"><i className="fa-solid fa-eye"></i> View</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3"><i className="fa-solid fa-share"></i> Share</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3"><i className="fa-solid fa-link"></i> Copy Link</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDeletePost(post._id)}><i className="fa-solid fa-trash-can"></i> Trash</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </li>
                    ))}
                </ul>
            </Tab>
            <Tab eventKey="profile" title="Drafts" disabled>
                Tab content for Profile
            </Tab>
            <Tab eventKey="contact" title="Trashed" disabled>
                Tab content for Contact
            </Tab>
        </Tabs>
    );
}

export default ControlledTabs;
