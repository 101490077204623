import React, { useContext } from 'react'
import './Posts.css'
import AvatarAsst from '../Avatar';
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { usePostCategory } from '../../Contexts/PostCategoryContext';
import LazyImage from '../LazyImage/LazyImage';

function Posts({ post }) {
    // Convert createdAt to a Date object
    const date = new Date(post.createdAt);

    const apiUrl = `${process.env.REACT_APP_RENDER_EXTERNAL_URL}`;

    const { setPostCategory } = usePostCategory();


    const navigate = useNavigate();

    // Format the date and time
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const handlePostDirector = (post) => {
        console.log(post);

        // Ensure post.category is an array and has at least one element
        if (Array.isArray(post.category) && post.category.length > 0) {
            const categorySlug = post.category[0].toLowerCase().replace(/\s/g, "-");
            const titleSlug = post.title.toLowerCase().replace(/\s/g, "-"); // Fallback method
            const postId = post._id;




            navigate(`/post/${categorySlug}/${postId}`, { state: { postId } });
        } else {
            console.error("Category is undefined or empty");
        }
    };

    const handleCategoryClick = async (category) => {
        console.log(category);
        setPostCategory(category);

        const path = category.toLowerCase().replace(/\s/g, "-");

        navigate(`/category/${path}`);
    };

    return (
        <div className="blog-post position-relative" key={post.id}>
            <div className="d-flex position-absolute p-2">
                {post.category.map(category => (
                    <span key={category} onClick={() => handleCategoryClick(category)} className="category">{category}</span>
                ))}
            </div>
            {/* <LazyImage src={`/api/image/${post.image}`} alt={post.title} /> */}
            <img onClick={() => handlePostDirector(post)} src={apiUrl + post.image} alt={post.title} />
            <div className="post-info">
                <span className='post-date'>{formattedDate} <span>&#9679;</span> {post.readTime} mins read</span>
                <h3 onClick={() => handlePostDirector(post)} > { post.title }</h3>
            <p onClick={() => handlePostDirector(post)}>{post.showContent}</p>
            <p className='author-info mt-2'>
                <Stack direction="horizontal" spacing={3}>
                    <AvatarAsst size={24} name={post.author} />
                    <div className='me-2'>
                        <p className='ms'>{post.author}</p>
                    </div>
                </Stack>
            </p>
        </div>
        </div >
    )
}

export default Posts