import React, { useContext } from 'react';
import { Carousel, Stack } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { usePostCategory } from '../../Contexts/PostCategoryContext';
import { useNavigate } from 'react-router-dom';
import AvatarAsst from '../Avatar';
import './CarouselItems.css';
import LazyLoad from 'react-lazyload';
import loadingImage from './loading.png';

function CarouselItems({ post }) {
    const { setPostCategory } = usePostCategory();

    const apiUrl = `${process.env.REACT_APP_RENDER_EXTERNAL_URL}`;

    const navigate = useNavigate();

    // Convert createdAt to a Date object
    const date = new Date(post.createdAt);

    // Format the date and time
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const handleCategoryClick = async (category) => {
        console.log(category);
        setPostCategory(category);

        const path = category.toLowerCase().replace(/\s/g, "-");

        navigate(`/category/${path}`);
    };

    const handlePostDirector = (post) => {
        console.log(post);

        // Ensure post.category is an array and has at least one element
        if (Array.isArray(post.category) && post.category.length > 0) {
            const categorySlug = post.category[0].toLowerCase().replace(/\s/g, "-");
            const titleSlug = post.title.toLowerCase().replace(/\s/g, "-"); // Fallback method
            const postId = post._id;




            navigate(`/post/${categorySlug}/${postId}`, { state: { postId } });
        } else {
            console.error("Category is undefined or empty");
        }
    };

    return (
        <div key={post.id}>
            {post.image ?
                // <img
                //     className="d-block w-100"
                //     src={post.image}
                //     alt={post.title}
                //     style={{background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${loadingImage})}`}}
                // />
                <div className="post-image" style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                    url(${apiUrl + post.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '700px',
                }}>
                </div> : <div className='loading-div' style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${loadingImage})}` }}></div>}

            {/* <LazyLoad height={200} offset={100}>
                <img src={post.imageUrl} alt={post.title} />
            </LazyLoad> */}
            {/* <LazyImage className="d-block w-100" src={`/api/image/${post.image}`} alt={post.title} /> */}
            <Carousel.Caption className="hero-text text-start d-none d-md-block" style={{ padding: "20px 70px" }}>
                <Row>
                    <Col md={8} className="hero-title">
                        {Array.isArray(post.category) && post.category.map(category => (
                            <span key={category} onClick={() => handleCategoryClick(category)} className="category">{category}</span>
                        ))}
                        <h3 onClick={() => handlePostDirector(post)} style={{ textShadow: "1px 1px 3px #505050" }}>{post.title}</h3>
                        <p style={{ textShadow: "1px 1px 3px #505050" }}>{post.showContent}</p>
                    </Col>
                    <Col md={4} className="author-info text-end">
                        <div className='d-none d-md-block'>
                            <Stack direction="horizontal" className='flex-row-reverse' spacing={2}>
                                <AvatarAsst name={post.author} size="sm" />
                                <div className='me-2 author'>
                                    <p style={{ margin: 0, textShadow: "1px 1px 3px #505050" }}>{post.author}</p>
                                    <p style={{ margin: 0, textShadow: "1px 1px 3px #505050" }} className="text-sm">{formattedDate} <span>&#9679;</span> {post.readTime} mins read</p>
                                </div>
                            </Stack>
                        </div>
                    </Col>
                </Row>
            </Carousel.Caption>
            <Carousel.Caption className="hero-text text-start d-block d-md-none" style={{ padding: "20px 20px" }}>
                <Row>
                    <Col md={8} className="hero-title">
                        {Array.isArray(post.category) && post.category.map(category => (
                            <span key={category} onClick={() => handleCategoryClick(category)} className="category">{category}</span>
                        ))}
                        <h3 onClick={() => handlePostDirector(post)} style={{ textShadow: "1px 1px 3px #505050", fontSize: '25px' }}>{post.title}</h3>
                        <p style={{ textShadow: "1px 1px 3px #505050", fontSize: '15px' }}>{post.showContent}</p>
                    </Col>
                    <Col md={4} className="author-info text-end">
                        <Stack direction="horizontal" className='flex-row d-sm-block d-md-none text-start' spacing={2}>
                            <AvatarAsst name={post.author} size="sm" />
                            <div className='me-2 author'>
                                <p style={{ margin: 0, textShadow: "1px 1px 3px #505050", fontSize: '15px' }}>{post.author}</p>
                                <p style={{ margin: 0, textShadow: "1px 1px 3px #505050", fontSize: '15px' }} className="text-sm">{formattedDate} <span>&#9679;</span> {post.readTime} mins read</p>
                            </div>
                        </Stack>
                    </Col>
                </Row>
            </Carousel.Caption>
        </div >
    );
}

export default CarouselItems;