import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="section">
      <h1>My Home</h1>
      <p>Your hub for next steps, support center, and quick links.</p>
      {/* Add more content as needed */}
    </div>
  );
};

export default Home;
