import React from 'react';
import Sidebar from '../../Components/AdminComponents/AdminSidebar/Sidebar';
import Home from '../../Components/AdminComponents/AdminHome/Home';
import Posts from '../../Components/AdminComponents/AdminPosts/Posts';
import Media from '../../Components/AdminComponents/AdminMedia/Media';
import './AdminLandingPage.css';
import { Route, Routes } from 'react-router-dom';
import { Col } from 'react-bootstrap';

const AdminLandingPage = () => {
  return (
    <div style={{ display: 'flex' }}>
      <Col md={2}>
        <div className='sidebar-div'>
          <Sidebar />
        </div>
      </Col>
      <Col md={10}>
        <main style={{ flexGrow: 0, padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/posts" element={<Posts />} />
            <Route path="/media" element={<Media />} />
          </Routes>
        </main>
      </Col>
    </div>
  );
}

export default AdminLandingPage;