import React from 'react'
import LandingPage from './LandingPage';



function CategoryPage({ mainContentMargin }) {
  return (
    <div>
        <LandingPage mainContentMargin={mainContentMargin} />
    </div>
  )
}

export default CategoryPage